import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring';

function NumberAnimation ({number, decimal=0, component, ...otherProps}) {

  const [num, setNum] = useState({prev: 0, current: 0});

  const animationProps = useSpring({val: num.current, from: {val: num.prev}});

  // update number
  useEffect(() => {
    setNum(prevState => ({
      prev: prevState.current,
      current: number
    }));
  }, [number]);

  const formatNumber = num => {
    if (decimal < 1) {
      return Math.floor(num);
    }

    return num.toFixed(decimal);
  };

  const Component = (component === undefined) ? <></> : animated(component);

  return (<>
    {
      (component === undefined)
        ? <animated.div>{animationProps.val.interpolate(formatNumber)}</animated.div>
        : <Component>{animationProps.val.interpolate(formatNumber)}</Component>
    }
  </>);

}

NumberAnimation.propTypes = {
  number: PropTypes.number.isRequired,
  decimal: PropTypes.number,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default NumberAnimation;
