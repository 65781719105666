import { combineReducers } from 'redux';

import userReducer from './user/userReducer';
import layoutReducer from './layout/layoutReducer';
import urlReducer from './url/urlReducer';
import cartReducer from './cart/cartReducer';
import openingReducer from './opening/openingReducer';
import settingReducer from './setting/settingReducer';
import menuReducer from './menu/menuReducer';
import seoReducer from './seo/seoReducer';


const rootReducer = combineReducers({
  user: userReducer,
  layout: layoutReducer,
  url: urlReducer,
  cart: cartReducer,
  opening: openingReducer,
  setting: settingReducer,
  seo: seoReducer,
  menu: menuReducer,
});

export default rootReducer;
