import PropTypes from 'prop-types';

export default function ThemeImage({src, ...attrs}) {
  const fullPath = `/static/theme/${src}`;
  return <img src={fullPath} {...attrs}  />
}

ThemeImage.prototypes = {
  src: PropTypes.string.isRequired
}

