const routes = {
    home: '/',

    // order: '/order',
    // orderConfirmation: '/order/confirmation',
    // orderSuccess: '/order/success',
    // orderHistory: '/order/history',

    gallery: '/gallery',
    about: '/about',
    wedding: '/wedding',
    offer: '/offers',
    entertainment: '/entertainment',
    food: '/food&drink',
    christmasMenu: '/christmas-menu',
    // reservation: '/reservation',
    contact: '/contact',
    // login: '/login',
    forgetPassword: '/forget-password',
    resetPassword: '/reset-password',
    signup: '/signup',
    points: '/points',
    privacyPolicy: '/privacy-policy',
    termsConditions: '/terms-and-conditions',
    allergyInformation: '/allergy-information',
    userProfile: '/profile',
    manage: '/manage',
    jobVacancy: '/job-vacancy',
  };

  export default routes;
