import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { loginStyles } from './login.style';
import { connect } from 'react-redux';
import { footerShowHide, headerShowHide } from '../../store/layout/layoutActions';
import { apiRequest } from '../../util/util';
import _ from 'lodash';
import routes from '../../util/routes';
import UserAvatar from '../../components/util/UserAvatar';
import { Alert } from '@material-ui/lab';
import { AUTH_ENDPOINT_FORGET_PASSWORD } from '../../util/constants';
import { CircularProgress } from '@material-ui/core';
import Copyright from '../../components/util/copyright';
import { formSingleValidator, formValidator, validationRules } from '../../util/formValidation';

const errorTypes = {
  SUBMIT: 'SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  FIELD_ERROR: 'FIELD_ERROR',
};

const errorDefaultState = {
  loading: false,
  error: false,
  success: false,
  message: '',
  fields: {
    email: false
  }
};

function errorReducer(state, {type, payload}) {
  switch (type) {
    case errorTypes.SUBMIT:
      return {
        ...state,
        loading: true
      }
    
    case errorTypes.SUBMIT_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: payload
      }
    
    case errorTypes.SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: (payload) ? payload : 'Something went wrong, please try again later'
      }
    
    case errorTypes.FIELD_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload
        }
      }
    
    default:
      return state;
  }
}

function ForgetPassword(props) {
  
  // redirect if user logged in
  useEffect(() => {
    if (props.isLoggedIn) {
      history.push(routes.home);
    }
  }, [props.user]);
  
  const classes = loginStyles();
  const history = useHistory();
  
  const [errorState, errorDispatch] = useReducer(errorReducer, errorDefaultState);
  
  const validationSchema = {
    email: [
      validationRules.required(),
      validationRules.email()
    ]
  };
  
  // hide/show header footer
  useEffect(() => {
    
    // hiding header footer
    toggleHeaderFooter(false);
    
    
    // showing header footer
    return () => toggleHeaderFooter(true);
    
  }, []);
  
  const toggleHeaderFooter = show => {
    props.showHideHeader(show);
    props.showHideFooter(show);
  }
  
  // handle form inputs
  const [credentials, setCredentials] = useState({
    email: ''
  });
  
  const changeHandler = e => {
    const name =  e.target.name;
    const value =  e.target.value;
    
    setCredentials({
      ...credentials,
      [name]: value
    });
  
    // handle field errors in realtime
    _.debounce(async ()=> {
    
      let validationErrors = await formSingleValidator({
        [name]: value
      }, validationSchema);
    
      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: validationErrors
      });
    }, 500)();
  };
  
  
  const submitHandler = async (e) => {
    e.preventDefault();
  
    // all field validation before submit
    const {error, data} = await formValidator(credentials, validationSchema);
  
    if (error) {
      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: data
      });
      return;
    }
    
    try {
  
      errorDispatch({
        type: errorTypes.SUBMIT
      });
  
      const res = await apiRequest.post(AUTH_ENDPOINT_FORGET_PASSWORD, {
        email: credentials.email
      });
  
      errorDispatch({
        type: errorTypes.SUBMIT_SUCCESS,
        payload: res.data.message
      });
      
    } catch (e) {
      errorDispatch({
        type: errorTypes.SUBMIT_FAIL,
        payload: e?.response?.data?.errors?.email[0]
      });
    }
  };
  
  return (
    <Grid container component="main" className={classes.root} direction="column" justify="center" alignItems="center">
      <CssBaseline />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6}>
        <div className={classes.paper}>
          
          {
            _.isEmpty(props.lastUser)
            ? <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
            : <UserAvatar />
          }
          
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
  
          {
            errorState.error &&
            <Alert severity="error" variant="standard">{errorState.message}</Alert>
          }
  
          {
            errorState.success &&
            <Alert severity="success" variant="standard">{errorState.message}</Alert>
          }
          
          
          <form className={classes.form} noValidate onSubmit={submitHandler}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={!!errorState.fields.email}
              helperText={errorState.fields.email}
              value={credentials.email}
              onChange={changeHandler}
            />
            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={errorState.loading}
            >
              Send Reset Link
              {
                errorState.loading &&
                <CircularProgress size="20px" style={{marginLeft: '10px'}} />
              }
            </Button>
            
            <Grid container>
              <Grid item xs>
                <RouterLink to={routes.login}>
                  Back to Login
                </RouterLink>
              </Grid>
              <Grid item>
                <RouterLink to={routes.signup}>
                  Don't have an account? Sign Up
                </RouterLink>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.data,
  lastUser: state.user.lastUser
});

const mapDispatchToProps = dispatch => ({
  showHideHeader: shouldShow => dispatch(headerShowHide(shouldShow)),
  showHideFooter: shouldShow => dispatch(footerShowHide(shouldShow)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
