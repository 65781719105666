import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import "../../assets/css/custom.scss";
import PageHeader from "../../components/pageHeader/pageHeader";
import SlideAnimation from "../../components/util/Animations/SlideAnimation";

function Offers() {

  return (
    <>
      <SlideAnimation in={true}>
        <PageHeader pageName="OFFERS" bgClass="breadcrumb-bg-services" />

        {/* features-4 */}

        <section className="grids-1">
          <div className={`grids pt-3 pb-5 backgroundDark`}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h1 className="latest-text">Our Offers</h1>

                  <div className="ent-image">
                    <img
                      src="/static/theme/images/offer1.jpg "
                      className="entertainment-image"
                      alt="indian curry"
                    />
                  </div>
                  <div className="ent-image">
                    <img
                      src="/static/theme/images/offer2.jpg "
                      className="entertainment-image"
                      alt="indian food and restaurants"
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </SlideAnimation>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  date: state.cart.delivery.date,
  time: state.cart.delivery.time,
  opening: state.opening.data,
});

export default connect(mapStateToProps)(Offers);
