import useMediaQuery from "@material-ui/core/useMediaQuery";
import { AutoRotatingCarousel, Slide } from "material-auto-rotating-carousel";
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// import { useHistory } from "react-router-dom";
import useSetting from '../../../hooks/useSetting';
import { updateShowPromotionalPopup } from '../../../store/layout/layoutActions';
import { SETTING_FILE_PATH } from '../../../util/constants';
import routes from '../../../util/routes';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';

const sliderStyles = makeStyles((theme) => ({
  content: {
    maxWidth: isMobile ? 'fit-content' : 400
  }
}));

function PromotionalSlider({ ...otherProps }) {

  const setting = useSetting([
    'site_title',
    'home_page_caption',
    'promotional_img_1',
    'promotional_img_2',
    'promotional_img_3',
    'promotional_title_1',
    'promotional_title_2',
    'promotional_title_3',
    'promotional_slider_enable',
    'promotional_img_background_color',
    'promotional_title_background_color',
    'promotional_btn_background_color',
    'promotional_btn_text_color',
    'promotional_extra_btn_show',
    'promotional_extra_btn_title',
    'promotional_extra_btn_link',

  ]);

  const sliderClasses = sliderStyles();

  const [promotionalModalHandler, setPromotionalModalHandler] = useState({
    open: true,
  });

  // const history = useHistory();
  const matches = useMediaQuery("(max-width:600px)");

  const [promotionalSlider, setPromotionalSlider] = useState(() => {
    return [
      {
        image: '',
        title: '',
        subtitle: '',
        links: [
          {
            title: 'Order Online',
            variant: 'primary',
            target: routes.order
          },
        ]
      },
      {
        image: '',
        title: '',
        subtitle: '',
        links: [
          {
            title: 'Order Online',
            variant: 'primary',
            target: routes.order
          },
        ]
      },
      {
        image: '',
        title: '',
        subtitle: '',
        links: [
          {
            title: 'Order Online',
            variant: 'primary',
            target: routes.order
          },
        ]
      },
    ];
  });

  useEffect(() => {

    const firstSlide = promotionalSlider[0];
    if (firstSlide.image) return;

    if (setting.promotional_img_1) {

      const newSlides = promotionalSlider?.map((itm, idx) => ({
        ...itm,
        image: setting[`promotional_img_${idx + 1}`],
        title: setting[`promotional_title_${idx + 1}`],
        subtitle: `Just using this will blow your mind.`,
      })).filter(itm => itm.image);

      setPromotionalSlider(newSlides);
    }

  }, [setting]);

  const handlePopup = () => {
    setPromotionalModalHandler({ open: false });
    setPromotionalModalHandler({ open: false });
    otherProps.updateShowPromotionalPopup(true);
  }

  const routePage = () => {
    // history.push(setting.promotional_extra_btn_link);
    otherProps.updateShowPromotionalPopup(true);
    window.open(setting.promotional_extra_btn_link, '_blank');

  }

  const AutoRotatingCarouselModal = ({
    promtionalModalHandler,
    setPromotionalModalHandler,
    isMobile }) => {

    return promotionalSlider.map((slider, idx) => (
      <div key={idx}>
        <AutoRotatingCarousel
          label="Close"
          ButtonProps={
            {
              "style": {
                backgroundColor: setting.promotional_btn_background_color,
                color: setting.promotional_btn_text_color,
                top: isMobile ? -9: -28,
              },
            }
          }
          hideArrows={!promotionalSlider.length}
          open={promtionalModalHandler.open}
          onClose={() => handlePopup()}
          onStart={() => handlePopup()}
          autoplay
          mobile={isMobile}
          classes={{
            content: sliderClasses.content
          }}
        >

          {
            promotionalSlider.filter(item => item.image).map((slider, idx2) => (<>
              <Slide
                key={idx2}
                media={
                  <img
                    style={{ maxWidth: "100%", cursor: 'pointer' }}
                    src={`${SETTING_FILE_PATH + slider.image}`}
                    alt="Promotional"
                    onClick={routePage}
                  />
                }
                mediaBackgroundStyle={{ backgroundColor: setting.promotional_img_background_color }}
                style={{ backgroundColor: setting.promotional_title_background_color, }}
                title={slider.title}
                subtitle={null}
                // subtitle={
                //   setting.promotional_extra_btn_show &&
                //   <Button
                //     type="button"
                //     variant="contained"
                //     onClick={routePage}
                //     style={{
                //       backgroundColor: setting.promotional_btn_background_color,
                //       color: setting.promotional_btn_text_color,
                //       marginRight:100,
                //       marginTop: 40,
                //     }}
                //   >
                //     {setting.promotional_extra_btn_title}
                //   </Button>
                // }
              />

            </>))

          }


        </AutoRotatingCarousel>
      </div>
    ))
  };


  return (<>

    {
      promotionalSlider[0]?.image && setting.promotional_slider_enable && !otherProps.promotional_banner_show &&
      <AutoRotatingCarouselModal
        isMobile={matches}
        promtionalModalHandler={promotionalModalHandler}
        setPromotionalModalHandler={setPromotionalModalHandler}
      />
    }

  </>);
}

const mapStateToProps = state => ({
  promotional_banner_show: state.layout.show_promotional_popup
});

const mapDispatchToProps = dispatch => ({
  updateShowPromotionalPopup: (val) => dispatch(updateShowPromotionalPopup(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromotionalSlider);



