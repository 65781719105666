import React, { useEffect, useState } from 'react';
import MainSlider from '../../components/slider/main/MainSlider';
import PromotionalSlider from '../../components/slider/promotionalSlider/PromotionalSlider';
import axios from 'axios';
import { connect } from 'react-redux';
import useSetting from '../../hooks/useSetting';
import { Box } from '@material-ui/core';
import { SETTING_FILE_PATH } from '../../util/constants';
import { Link } from 'react-router-dom';
import About from '../about/about';
import ReservationComponent from '../../components/reservation/reservation';
import routes from '../../util/routes';
import _ from 'lodash';
import PageLoader from '../../components/util/PageLoader';
import { red } from '@material-ui/core/colors';
import ReviewSlider from '../../components/slider/ReviewSlider';
import Gallery from '../gallery/index';
import '../../assets/css/custom.scss';
import SlideAnimation from '../../components/util/Animations/SlideAnimation';

axios.defaults.withCredentials = true;


const HomePage = ({ ...otherProps }) => {



  const homeSetting = useSetting([
    'allergy_information',
    'site_title',
    'food_img_1',
    'food_img_2',
    'food_img_3',
    'food_img_4',
    'food_img_5',
    'food_img_title_1',
    'food_img_title_2',
    'food_img_title_3',
    'food_img_title_4',
    'food_img_title_5',
    'review_img_1',
    'review_img_2',
  ]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    otherProps.settings.loading ? setIsLoading(true) : setIsLoading(false);

  }, [otherProps.settings.loading]);


  return (<>


    <PageLoader show={isLoading} backgroundColor="#5E0207" />
    <SlideAnimation in={true}>
      <MainSlider />

      {/* <Gallery hideBredCrumb={true} /> */}



      <h1 hidden>
        The Royal Tiger || Royal Tiger, Chelmsford, Takeaway Order Online
      </h1>

      <h3 hidden>
        A blend of the exotic and the oriental, this concept restaurant is inspired by Royal Thailand.
      </h3>

      {/* home page service grids */}
      <section id="services" className="home-services pt-5 pb-5">


        <div className="container pt-3">

          <About showBtn={true} />

        </div>
      </section>

      <PromotionalSlider />


      <section id="services" className="home-services pt-5 pb-5">


        <div className="container pt-3">

          <div className="row ">
            <div className="col-md-6 order-card">
              <a href="https://royaltigeressex.com/order-now">
                <div className="card">
                  <img className="card-img-top" src="/static/theme/images/r-main.jpg" alt="delicious Indian food" />
                  <div className="card-body">
                    <h3 className="card-text">Royal Tiger Menu</h3>
                    {/* <a class="btn btn-primary" href="#">Royal Tiger Menu</a> */}
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-6 order-card">
              <a target="_blank" href="https://royaltigeressex.com/order-now">
                <div className="card">
                  <img className="card-img-top" src="/static/theme/images/royal-thai.jpg" alt="Royal Tiger indian restaurant" />
                  <div className="card-body">
                    <h3 className="card-text">Royal Thai Menu</h3>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-6 order-card">
              <a target="_blank" href="https://royaltigeressex.com/order-now">
                <div className="card">
                  <img className="card-img-top" src="/static/theme/images/royal-indian.jpg" alt="takeaway near me" />
                  <div className="card-body">
                    <h3 className="card-text">Royal Indian Menu</h3>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-md-6 order-card">
              <a target="_blank" href="https://royaltigeressex.com/order-now">
                <div className="card">
                  <img className="card-img-top" src="/static/theme/images/r-sushi.jpg" alt="food and curry" />
                  <div className="card-body">
                    <h3 className="card-text">Tiger Sushi Menu</h3>
                  </div>
                </div>
              </a>
            </div>
          </div>

        </div>
      </section>





      {/* Reservation Component */}

      {/* what we do grids */}
      {/* <section className="grids-1 py-5 food-background">
    <div className="grids">
        <div className="container">
          <h3 className="title-big text-center">SPECIAL MENU</h3>
          <div className="row text-center grids-gap pt-4">
            <div className="col-lg-4 col-md-4 col-sm-6 dish-image">

              <img src={`${SETTING_FILE_PATH + homeSetting.food_img_1}`} className="food-image" alt={homeSetting.food_img_title_1} />
              <h4 className="food-title">{homeSetting.food_img_title_1}
                <span className="food-price"> (£16.95)</span>
              </h4>

            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 mt-sm-0 mt-4 dish-image">

              <img src={`${SETTING_FILE_PATH + homeSetting.food_img_2}`} className=" food-image" alt={homeSetting.food_img_title_2} />
              <h4 className="food-title">{homeSetting.food_img_title_2}
                <span className="food-price"> (£7.25)</span>

              </h4>

            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 mt-md-0 mt-4 dish-image">

              <img src={`${SETTING_FILE_PATH + homeSetting.food_img_3}`} className=" food-image" alt={homeSetting.food_img_title_3} />
              <h4 className="food-title">{homeSetting.food_img_title_3}
                <span className="food-price"> (£4.95)</span>

              </h4>

            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 mt-lg-0 mt-4 dish-image">

              <img src={`${SETTING_FILE_PATH + homeSetting.food_img_4}`} className=" food-image" alt={homeSetting.food_img_title_4} />
              <h4 className="food-title">{homeSetting.food_img_title_4}
                <span className="food-price"> (£8.25)</span>

              </h4>

            </div>

            <div className="col-lg-4 col-md-4 col-sm-6 mt-sm-0 mt-4 dish-image">

              <img src={`${SETTING_FILE_PATH + homeSetting.food_img_5}`} className=" food-image" alt={homeSetting.food_img_title_5} />
              <h4 className="food-title">{homeSetting.food_img_title_5}
                <span className="food-price"> (£11.50)</span>

              </h4>

            </div>

          </div>
        </div>
      </div>
    </section> */}

      {/*<ReservationComponent />*/}


      {/* <section id="services" className="w3l-stats  services py-5 ">
      <div className="container">
        <h1 className="title-big text-white text-center fugaz-font">Reviews</h1>
        <div className="row text-center img-grids">
          <div className={`col-12 reviewHover`}>
            <ReviewSlider />
          </div>
        </div>
      </div>
    </section> */}


      {/*  Allergy section */}
      <section className="w3l-stats parallax-allergy-section">
        <div className="main-w3 py-5" id="stats">


          <div className="container text-center">
            <h2 className="text-white fugaz-font ">Allergy Information</h2>
            <Box marginBottom={3} marginTop={3} className="cover-content">
              <h5 className="text-white text-left">{homeSetting.allergy_information}</h5>
            </Box>

            <a href="https://royaltigeressex.com/order-now" className="read-more btn btn-style btn-primary" >
              View Our Menu
            </a>


          </div>

        </div>
      </section>

    </SlideAnimation>
  </>);
}

const mapStateToProps = state => ({
  user: state.user.data,
  lastUser: state.user.lastUser,
  items: state.menu.items,
  settings: state.setting,
});

export default connect(mapStateToProps)(HomePage);
