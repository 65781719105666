import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/custom.scss';
import Img1 from '../../assets/images/wedding/wedd1.jpg';
import Img2 from '../../assets/images/wedding/wedd2.jpg';
import Img3 from '../../assets/images/wedding/wedd3.jpg';
import Img4 from '../../assets/images/wedding/wedd4.jpg';
import Img5 from '../../assets/images/wedding/wedd5.jpg';
import Img6 from '../../assets/images/wedding/wedd6.jpg';
import Img7 from '../../assets/images/wedding/wedd7.jpg';
import Img8 from '../../assets/images/wedding/latest01.jpg';
import Img9 from '../../assets/images/wedding/latest02.jpg';
import Img10 from '../../assets/images/wedding/offer3.jpg';

import useSetting from '../../hooks/useSetting';
import routes from '../../util/routes';
import useStyles from './wedding.style';

function Wedding() {

  const classes = useStyles();

  const setting = useSetting([
    'about_img',
    'about_description',
    'site_title',
  ]);

  const images = [Img5, Img6, Img7, Img8, Img9, Img10];


  return (<>

    <Grid container className={'container'}>
      <Grid item>
        <Typography
          component={Box}
          variant={'h5'}
          className={'mt-5'}
          paragraph
        >
          { setting.site_title } is A Luxurious Wedding & Events Venue with The Capacity Of Upto 300 Guests In Chelmsford, Essex.
        </Typography>

        <Typography
          component={Box}
          variant={'h5'}
          className={'mt-5'}
          paragraph
        >
          Call us on <a href="tel:+441245403030" className={'text-danger'}>(+44)-1245 403030</a> or contact us
          <Link to={routes.contact}> <span className={'text-danger'}>from here</span></Link>
        </Typography>
      </Grid>
    </Grid>


    <Grid
      container
    >
      {images.map((img, idx) => (
        <Grid
          item
          key={`wedd-${idx}`}
          md={6}
          style={{
            padding: '3px'
          }}
        >
          <img
            src={img}
            className="img-fluid"
            alt="restaurant for Delivery & Collection."
          />
        </Grid>
      ))}
    </Grid>



  </>);
}


export default Wedding;
