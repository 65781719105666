import { FOOTER_HIDE, FOOTER_SHOW, HEADER_HIDE, HEADER_SHOW, SHOW_AUTO_OFFER_POPUP, SHOW_LEFT_SIDE_BAR, SHOW_PROMOTIONAL_POPUP } from './layoutTypes';

const defaultState = {
  show_header: true,
  show_footer: true,
  show_promotional_popup: false,
  show_auto_offer_popup: false,
  show_left_side_bar: false,
};

const reducer = (state = defaultState, { type, payload }) => {

  switch (type) {
    case HEADER_SHOW:
      return {
        ...state,
        show_header: true
      }

    case HEADER_HIDE:
      return {
        ...state,
        show_header: false
      }

    case FOOTER_SHOW:
      return {
        ...state,
        show_footer: true
      }

    case FOOTER_HIDE:
      return {
        ...state,
        show_footer: false
      }
    case SHOW_PROMOTIONAL_POPUP:
      return {
        ...state,
        show_promotional_popup: payload
      }
    case SHOW_AUTO_OFFER_POPUP:
      return {
        ...state,
        show_auto_offer_popup: payload
      }
    case SHOW_LEFT_SIDE_BAR:
      return {
        ...state,
        show_left_side_bar: payload,
      }

    default: return state
  }

};

export default reducer;
