import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import useStyles from './popupMobile.style';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopupMobile({open, openHandler, title, ...otherProps}) {
  const classes = useStyles();
  
  return (
    <div>
      <Dialog fullScreen open={open} onClose={()=>openHandler(false)} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <Typography variant="h6" className={classes.title}>{title}</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={()=>openHandler(false)}
              aria-label="close"
              className={classes.closeBtn}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {
          otherProps.children
        }
      </Dialog>
    </div>
  );
}

PopupMobile.propTypes = {
  open: PropTypes.bool.isRequired,
  openHandler: PropTypes.func.isRequired,
  title: PropTypes.string
}
