import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { loginStyles } from './login.style';
import { connect } from 'react-redux';
import { footerShowHide, headerShowHide } from '../../store/layout/layoutActions';
import { login } from '../../store/user/userActions';
import _ from 'lodash';
import routes from '../../util/routes';
import Copyright from '../../components/util/copyright';
import { formSingleValidator, formValidator, validationRules } from '../../util/formValidation';
import { Alert } from '@material-ui/lab';
import useRedirectIfAuthenticated from '../../hooks/useRedirectIfAuthenticated';
import useRedirectToIntended from '../../hooks/useRedirectToIntended';
import { CircularProgress } from '@material-ui/core';

const errorTypes = {
  SUBMIT: 'SUBMIT',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
  SUBMIT_FAIL: 'SUBMIT_FAIL',
  FIELD_ERROR: 'FIELD_ERROR',
};

const errorDefaultState = {
  loading: false,
  error: false,
  success: false,
  message: '',
  fields: {
    email: false,
    password: false,
    remember: false
  }
};

function errorReducer(state, { type, payload }) {
  switch (type) {
    case errorTypes.SUBMIT:
      return {
        ...state,
        loading: true
      }

    case errorTypes.SUBMIT_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        loading: false,
        message: payload
      }

    case errorTypes.SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        message: (payload) ? payload : 'Something went wrong, please try again later'
      }

    case errorTypes.FIELD_ERROR:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...payload
        }
      }

    default:
      return state;
  }
}

function Login(props) {

  // redirect users if they already authenticated
  useRedirectIfAuthenticated();

  // redirect to the page they wanted to go before interception
  useRedirectToIntended();

  const classes = loginStyles();


  const [errorState, errorDispatch] = useReducer(errorReducer, errorDefaultState);

  // hide/show header footer
  useEffect(() => {

    // hiding header footer
    toggleHeaderFooter(false);

    // showing header footer
    return () => toggleHeaderFooter(true);

  }, []);

  const toggleHeaderFooter = show => {
    props.showHideHeader(show);
    props.showHideFooter(show);
  }

  const [isloading, setIsLoading] = useState(false);

  // handle form inputs
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    remember: false
  });

  const validationSchema = {
    email: [
      validationRules.required(),
      validationRules.email()
    ],
    password: [
      validationRules.required()
    ]
  };

  const rememberChangeHandler = e => {
    setCredentials({
      ...credentials,
      remember: e.target.checked
    });
  };
  const changeHandler = e => {
    const name = e.target.name;
    const value = e.target.value;

    setCredentials({
      ...credentials,
      [name]: value
    });

    // handle field errors in realtime
    _.debounce(async () => {

      let validationErrors = await formSingleValidator({
        [name]: value
      }, validationSchema);

      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: validationErrors
      });
    }, 500)();
  };


  const submitHandler = async (e) => {
    
    setIsLoading(true);
    
    e.preventDefault();

    // all field validation before submit
    const { error, data } = await formValidator(credentials, validationSchema);

    if (error) {
      errorDispatch({
        type: errorTypes.FIELD_ERROR,
        payload: data
      });
      
      setIsLoading(false);
      
      return;
    }

    await props.login(credentials.email, credentials.password, credentials.remember);
    setIsLoading(false);
     
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>

          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Log in
          </Typography>

          <Typography component="h4" variant="h5">
            <div class="alert alert-info" role="alert">
              We've upgraded our system. So, if you've existing account, you can reset your password from <RouterLink className={classes.resetLink} to={routes.forgetPassword}>
                here
              </RouterLink>.
            </div>
          </Typography>

          {
            props.url.isRedirected &&
            <Alert severity="warning" variant="standard">Please login to continue</Alert>
          }
          {
            props.errors?.email &&
            <Alert severity="error" variant="standard">{props.errors.email[0]}</Alert>
          }

          <form className={classes.form} noValidate onSubmit={submitHandler}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={!!errorState.fields.email}
              helperText={errorState.fields.email}
              value={credentials.email}
              onChange={changeHandler}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={!!errorState.fields.password}
              helperText={errorState.fields.password}
              value={credentials.password}
              onChange={changeHandler}
            />
            <FormControlLabel
              control={<Checkbox
                value="remember"
                color="primary"
                checked={credentials.remember}
                onChange={rememberChangeHandler}
              />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >

              Sign In

              {
                isloading &&
                <CircularProgress color="#fff" size={25} style={{ marginLeft: 7 }} />
              }
            </Button>

            <Grid container>
              <Grid item xs>
                <RouterLink to={routes.forgetPassword}>
                  Forgot password?
                </RouterLink>
              </Grid>
              <Grid item>
                <RouterLink to={routes.signup}>
                  Don't have an account? Sign Up
                </RouterLink>
              </Grid>
            </Grid>

            <Box>
              <Box display="flex" justifyContent="center" my={4}>
                <Typography component="h1" variant="h5">OR</Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <RouterLink to={routes.home}>
                  <Button variant="outlined" color="primary">Back to Home</Button>
                </RouterLink>
              </Box>
            </Box>

            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.data,
  errors: state.user.errors,
  url: state.url
});

const mapDispatchToProps = dispatch => ({
  showHideHeader: shouldShow => dispatch(headerShowHide(shouldShow)),
  showHideFooter: shouldShow => dispatch(footerShowHide(shouldShow)),
  login: (email, password, remember) => dispatch(login(email, password, remember))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
