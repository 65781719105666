import React from 'react';
import PageHeader from '../../components/pageHeader/pageHeader';
import Wedding from './Wedding';
import SlideAnimation from '../../components/util/Animations/SlideAnimation';

function WeddingPage() {


  return (<SlideAnimation in={true}>
    <>
      <PageHeader pageName="Wedding" bgClass="breadcrumb-bg-wedding" />

      <section className="w3l-services-6-main home-services">
        <div className="services-6 py-5 px-1">
          <Wedding />
        </div>
      </section>
    </>
  </SlideAnimation>);
}

export default WeddingPage;
