import React from 'react';
import PageHeader from '../../components/pageHeader/pageHeader';
import ThemeImage from '../../components/util/ThemeImage';
import { connect } from 'react-redux';
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import '../../assets/css/custom.scss';
import useNotify from '../../hooks/useNotify';
import SlideAnimation from '../../components/util/Animations/SlideAnimation';


function Food() {

  const [notify] = useNotify();

 

  return (<>

    <SlideAnimation in={true}>

      <PageHeader pageName="Food & Drinks" bgClass="breadcrumb-bg-services" />

      {/* features-4 */}

      <section className="grids-1">
        <div className={`grids pt-3 pb-5 backgroundDark`}>
          <div className="container row">

           
            <div className="col-md-6 col-lg-6 col-sm-12 mb-3">
              <Card>
                <h4 className="welcome-text">
                  Gourmet Indian &amp; Thai Fusion
                </h4>
              </Card>

            </div>

            <div className="col-md-6 col-lg-6 col-sm-12 mb-3">

              <Card>
                <h4 className="welcome-text">
                  Luxury two course menu including appetisers
                </h4>
              </Card>
            </div>


            <div className="col-md-6 col-lg-6 col-sm-12 mb-3 cursor" onClick={() => notify.info("Royal Tiger main menu pdf will upload soon...")}>
              <Card>
                <h4 className="welcome-text " >
                  Royal Tiger Main Menu
                </h4>
              </Card>

            </div>

            <div className="col-md-6 col-lg-6 col-sm-12 ">

              <Card>
                <h4 className="welcome-text">
                  <a href="/static/theme/drink_n_wine.pdf" className="text-left cursor menu-a-tag" target="_blank">Royal Tiger Wine &amp; Drinks Menu</a>
                </h4>
              </Card>
            </div>

          </div>

        </div>


      </section >

    </SlideAnimation>
  </>);
}

const mapStateToProps = state => ({
  user: state.user,
  date: state.cart.delivery.date,
  time: state.cart.delivery.time,
  opening: state.opening.data,
});

export default connect(mapStateToProps)(Food);
