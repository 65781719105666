import { useSnackbar } from "notistack";
import Slide from "@material-ui/core/Slide";
import { Close } from "@material-ui/icons";
import {IconButton} from "@material-ui/core";
import * as colors from '@material-ui/core/colors'

const getCloseBtnColor = (type) => {
  let color;

  switch (type) {
    case 'success':
      color = colors.green["100"];
      break;

    case 'error':
      color = colors.red["100"];
      break;

    case 'info':
      color = colors.blue["100"];
      break;

    default:
      color = colors.grey["500"];
      break;
  }

  return {color}
};

function useNotify(positionY='top', positionX='center') {
  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const notify = (msg, type='default', duration=5000) => {
    
    const action = key => (<>
      <IconButton  onClick={() => { closeSnackbar(key) }}>
        <Close style={getCloseBtnColor(type)} />
      </IconButton>
    </>);

    enqueueSnackbar(msg, {
      variant: type,
      anchorOrigin: {
        vertical: positionY,
        horizontal: positionX,
      },
      TransitionComponent: Slide,
      autoHideDuration: duration,
      action
    });
  };

  const types = {
    default: (msg, duration) => notify.call(null, msg, 'default', duration),
    success: (msg, duration) => notify.call(null, msg, 'success', duration),
    error: (msg, duration) => notify.call(null, msg, 'error', duration),
    info: (msg, duration) => notify.call(null, msg, 'info', duration),
    warning: (msg, duration) => notify.call(null, msg, 'warning', duration),
  };

  return [types, closeSnackbar];
}

export default useNotify;
