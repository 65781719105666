import {Button} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import "../../assets/css/custom.scss";
import PageHeader from "../../components/pageHeader/pageHeader";
import SlideAnimation from "../../components/util/Animations/SlideAnimation";
import {API_GET_ENTERTAINMENTS, ASSET_FILE_PATH} from "../../util/constants";
import {apiRequest} from "../../util/util";

function Awards() {
  const [showbtn, setShowBtn] = useState(false);
  const [btnName, setBtnName] = useState('Show Archives');

  const [events, setEvents] = useState({
    upcoming: [],
    past: [],
  });

  useEffect(() => {

    (async () => {
      const res = await apiRequest.get(API_GET_ENTERTAINMENTS);
      const data = res.data.data;

      if ('upcoming' in data) {
        setEvents(() => ({
          ...data
        }))
      }
    })()

  }, []);

  const handleShow = () => {
    setShowBtn((v) => !v);
    if (btnName === 'Show Archives') {
      setBtnName('Hide Archives')
    } else {
      setBtnName('Show Archives')
    }
  };

  const imgPath = `${ASSET_FILE_PATH}entertainment/`;

  return (
    <>
      <SlideAnimation in={true}>
        <PageHeader pageName="Entertainment" bgClass="breadcrumb-bg-services"/>

        {/* features-4 */}

        <section className="grids-1">
          <div className={`grids pt-3 pb-5 backgroundDark`}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h1 className="latest-text">Upcoming Events</h1>

                  <h4 className="welcome-text">
                    We have some great regular entertainment at The Royal
                    Tiger. Aside from the regular schedule we have frequent
                    tribute acts, musicians, and other special events. Give
                    us a call or check out our Facebook page for the very
                    latest info.
                  </h4>

                  {
                    events.upcoming.map((upcomingEvent) => (
                      <div className="ent-image">
                        <img
                          loading="lazy"
                          src={imgPath + upcomingEvent.image}
                          className="entertainment-image"
                          alt={`Royal Tiger event starts on ${upcomingEvent.start_date}`}
                        />
                      </div>
                    ))
                  }


                  <div className="text-center">
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={handleShow}
                      style={{padding: 7}}
                    >
                      {btnName}
                    </Button>
                  </div>

                  {showbtn && events.past.map((pastEvent) => (
                    <div className="ent-image">
                      <img
                        loading="lazy"
                        src={imgPath + pastEvent.image}
                        className="entertainment-image"
                        alt={`Royal Tiger event ended on ${pastEvent.end_date}`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </SlideAnimation>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  date: state.cart.delivery.date,
  time: state.cart.delivery.time,
  opening: state.opening.data,
});

export default connect(mapStateToProps)(Awards);
