import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import { Box, Divider, Grid } from '@material-ui/core';
import { cartItemAddon } from '../../pages/order/cart/cart.style';
import { connect } from 'react-redux';
import CartItemMenu from './cartItemMenu';
import { CURRENCY_SYMBOL } from '../../util/constants';


function CartItem({item, ...otherProps}) {
  
  const cartItemAddonStyle = cartItemAddon();
  
  const addons = () => {
    return otherProps.addons
      .filter(addon => addon.item_id === item.id)
      .map(addon => (
      <Box
        key={addon.id}
        display="flex"
        justifyContent="space-between"
        className={cartItemAddonStyle.root}
      >
        <Box style={{fontSize: 15}}>{addon.name}</Box>
        <Box>{CURRENCY_SYMBOL}{(addon.price * item.qty).toFixed(2)}</Box>
      </Box>
    ));
  };
  
  return (
    <ListItem>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Box style={{fontSize: 17}}>{item.name}</Box>
            <Box display="flex">
              <Box mr={1}>
                <CartItemMenu item={item} qty={item.qty} />
              </Box>
              <Box>
                {CURRENCY_SYMBOL}
                {
                  (item.price * item.qty).toFixed(2)
                }
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {
            addons()
          }
        </Grid>
      </Grid>
      <Divider />
    </ListItem>
  );
}

const mapStateToProps = state => ({
  addons: state.cart.addons
});

export default connect(mapStateToProps)(CartItem);
