import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { footerShowHide, headerShowHide } from '../../store/layout/layoutActions';
import { connect } from 'react-redux';
import _ from 'lodash';

const Error404Page = (props) => {
  
  // hide/show header footer
  useEffect(() => {
    
    // hiding header footer
    toggleHeaderFooter(false);
    
    // showing header footer
    return () => toggleHeaderFooter(true);
    
  }, []);
  
  const toggleHeaderFooter = show => {
    props.showHideHeader(show);
    props.showHideFooter(show);
  }
  
  return (
    <div>
      <h1>Sorry! Page not found!!</h1>
      <h3>
        Back to
        <Link to="/">
          <Button variant="contained" color="primary">HOME</Button>
        </Link>
      </h3>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  showHideHeader: shouldShow => dispatch(headerShowHide(shouldShow)),
  showHideFooter: shouldShow => dispatch(footerShowHide(shouldShow))
});

export default connect(null, mapDispatchToProps)(Error404Page);
