import {
  ADDED_MENUS,
  SELECTED_MENU
} from './menuTypes';

const defaultState = {
  categories: [],
  items: [],
  selectedCategory: {},
  selectedItems: [],
};


const MenuReducer = (state = defaultState, { type, payload }) => {

  switch (type) {

    case ADDED_MENUS:
      return {
        ...state,
        categories: [...payload.categories],
        items: { ...payload.items },
      }
    case SELECTED_MENU:
      return {
        ...state,
        selectedCategory: {...payload.selectedCategory},
        selectedItems: [payload.selectedItems],
      }


    default: return state
  }

};

export default MenuReducer;