import React from 'react';
import { Link } from 'react-router-dom';
import useSetting from '../../hooks/useSetting';
import { SETTING_FILE_PATH } from '../../util/constants';
import '../../assets/css/custom.scss';

function About({ showBtn }) {

  const aboutSetting = useSetting([
    'about_img',
    'about_title',
    'about_description',
    'site_title',
  ]);


  return (<>

    <div className="row serv_sec_info">
      <div className="col-lg-6 banner_bottom_grid help">
        <img src={`${SETTING_FILE_PATH + aboutSetting.about_img}`} className="img-fluid radius-image" height="300" alt="Royal Tiger restaurant" />
      </div>
      <div className="col-lg-6 banner_bottom_left pl-lg-4 mt-lg-0 mt-md-5 mt-4 text-center">
        <h2 className="title-big text-center about-title">About {aboutSetting.site_title}</h2>

        {
          showBtn &&
          // <p className="about-description">{aboutSetting.about_description?.substring(0, 500)}</p>
          <p className="about-description">
            Based in Chelmsford, The Royal Tiger is a restaurant like no other! Bringing consumers a place to enjoy Indian food, Thai food and Sushi all in one place. 
            The Sushi we make is prepared to seize the rich tastes of oriental-styled delicacies, and each Thai meal is cooked to encapsulate the authentic 
            taste of well-made Thai food. <br></br> <br /> All whilst our Indian cuisines aim to maintain the well-renowned reputations of the culture's food. ...
          </p>

        }

        {
          !showBtn &&
          <p className="about-description">
            Based in Chelmsford, The Royal Tiger is a restaurant like no other! Bringing consumers a place to enjoy Indian food, Thai food and Sushi all in one place. 
            The Sushi we make is prepared to seize the rich tastes of oriental-styled delicacies, and each Thai meal is cooked to encapsulate the authentic 
            taste of well-made Thai food.<br></br> <br /> All whilst our Indian cuisines aim to maintain the well-renowned reputations of the culture's food. 
            We place a large focus on capturing the essence of cultural flavours, so that customers can feel as though they're eating in the meal's country of origin.
          </p>

        }

        {showBtn &&
          <Link to="/about" className="btn btn-primary btn-style mt-4" >Read More</Link>
        }
      </div>
    </div>


  </>);
}


export default About;
