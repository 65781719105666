import {
  ADDED_MENUS,
  SELECTED_MENU,
} from './menuTypes';

export const MenuAdded = (categories, items) => {

  return dispatch => {
    dispatch({
      type: ADDED_MENUS,
      payload: { categories, items }
    });
  }
};

export const SelectedMenuAdded = (selectedCategory, selectedItems) => {
  return dispatch => {
    dispatch({
      type: SELECTED_MENU,
      payload: {selectedCategory, selectedItems}
    });
  }
};


