///// Authentication /////
export const LOGIN_PRE_REQUEST = 'LOGIN_PRE_REQUEST';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS';
export const LOGIN_REQUEST_FAIL = 'LOGIN_REQUEST_FAIL';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_REQUEST_SUCCESS = 'LOGOUT_REQUEST_SUCCESS';
export const LOGOUT_REQUEST_FAIL = 'LOGOUT_REQUEST_FAIL';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_REQUEST_SUCCESS = 'GET_USER_REQUEST_SUCCESS';
export const GET_USER_REQUEST_FAIL = 'GET_USER_REQUEST_FAIL';

export const ADD_USER_ADDRESSES = 'ADD_USER_ADDRESSES';

export const DELETE_USER_ADDRESS = 'DELETE_USER_ADDRESS';

export const UPDATE_USER_ADDRESS = 'UPDATE_USER_ADDRESS';

export const UPDATE_USER_POINTS = 'UPDATE_USER_POINTS';

export const UPDATE_ERROR = 'UPDATE_ERROR';

export const REQUIRE_AUTH = 'REQUIRE_AUTH';

