import { useEffect } from 'react';
import routes from '../util/routes';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

/**
 * Redirects to redirectPath if user is authenticated
 * @param redirectPath
 */

function useRedirectIfAuthenticated(redirectPath=routes.home) {
  
  const user = useSelector(state => state.user);
  const history = useHistory();
  
  useEffect(() => {
    
    if (user.isLoggedIn) {
      history.push(redirectPath);
    }
  }, [user]);
  
}

export default useRedirectIfAuthenticated;
