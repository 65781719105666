import {
  SETTING_GET_REQUEST,
  SETTING_GET_FAIL,
  SETTING_GET_SUCCESS
} from './settingTypes';

const defaultState = {
  loading: false,
  data: [],
  error: '',
  errors: {}
};

const reducer = (state = defaultState, {type, payload}) => {
  
  switch (type) {
    
    case SETTING_GET_REQUEST:
      return {
        ...state,
        loading: true
      }
  
    case SETTING_GET_FAIL:
      return {
        ...state,
        loading: false,
        data: [],
        error: payload.message,
        errors: payload?.errors || {}
      }
      
    case SETTING_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload,
        error: '',
        errors: {}
      }
      
    default: return state
  }
  
};

export default reducer;
