import React, { useEffect, useRef, useState } from 'react';
import NavItem from './navItem';
import { connect } from 'react-redux';
import Logout from '../../pages/auth/logOut';
import UserAvatar from '../util/UserAvatar';
import { Link } from 'react-router-dom';
import useSetting from '../../hooks/useSetting';
import { SETTING_FILE_PATH } from '../../util/constants';
import routes from "../../util/routes";
import { makeStyles, Typography } from "@material-ui/core";
import { isMobile } from "react-device-detect";


const useStyles = makeStyles({
  navbarFixed: {
    width: "100vw",
    position: "fixed",
    zIndex: 11,
  },
});


function Nav({ ...otherProps }) {

  const classes = useStyles();

  const setting = useSetting([
    'phone',
    'site_logo_large',
    'navbar_fixed',
  ]);
  const mobileNavToggleRef = useRef(false);

  return (
    <header className={`w3l-header-nav  ${setting.navbar_fixed ? classes.navbarFixed : ''}`}>
      {/*/nav*/}
      <nav className="navbar navbar-expand-lg navbar-light fill px-lg-0 py-0 px-3">
        <div className="container nav-container">
          <Link to="/" className="navbar-brand"><img src={`${SETTING_FILE_PATH + setting.site_logo_large}`} height="50" /></Link>

          <button
            ref={mobileNavToggleRef}
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="fa icon-expand fa-bars" />
            <span className="fa icon-close fa-times" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">

              <NavItem name="Home" path="/" mobileMenuToggleRef={mobileNavToggleRef} />
              <li className="nav-item gb-dropdown">
                <a className="nav-link">Order Online</a>
                <ul className="gb-dropdown-menu">
                  <li className="nav-item">
                    <a className="nav-link" href="https://royaltigeressex.com/order-now">Royal Tiger Menu</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://royaltigeressex.com/order-now">Royal Thai Menu</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://royaltigeressex.com/order-now">Royal Indian Menu</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="https://royaltigeressex.com/order-now">Tiger Sushi Menu</a>
                  </li>
                </ul>
              </li>

              <a href="https://royaltigeressex.com/book-table" className="nav-link">Reservation</a>
              <NavItem name="Offers" path={routes.offer} mobileMenuToggleRef={mobileNavToggleRef} />

              {/*<NavItem name="Reservation" path={routes.reservation} mobileMenuToggleRef={mobileNavToggleRef} />*/}
              <NavItem name="Wedding" path={routes.wedding} mobileMenuToggleRef={mobileNavToggleRef} />

              <NavItem name="Entertainment" path={routes.entertainment} mobileMenuToggleRef={mobileNavToggleRef} />
              <NavItem name="Food/Drink" path={routes.food} mobileMenuToggleRef={mobileNavToggleRef} />
              <NavItem name="Christmas Season" path={routes.christmasMenu} mobileMenuToggleRef={mobileNavToggleRef} />

              <NavItem name="Gallery" path="/gallery" mobileMenuToggleRef={mobileNavToggleRef} />
              <NavItem name="Contact" path="/contact" mobileMenuToggleRef={mobileNavToggleRef} />



              {/* {
                otherProps.isLoggedIn
                  ? <Logout name="LogOut" mobileMenuToggleRef={mobileNavToggleRef} />
                  : <NavItem name="Login" path="/login" mobileMenuToggleRef={mobileNavToggleRef} />
              } */}

            </ul>
            {/*/User avatar*/}
            {/* {
              otherProps.isLoggedIn &&
              <Link to={routes.userProfile}>
                <div
                  className="search-right"
                  onClick={() => {
                    if (isMobile) {
                      mobileNavToggleRef?.current?.click();
                    }
                  }}
                >
                  <UserAvatar />
                </div>
              </Link>
            } */}


            {/*//search-right*/}
            {/* <div className="box-support">
              <p>For Support Call <span className="fa fa-headphones" /></p>

              <Typography variant="h5">
                <a href={`tel:${setting.phone}`}>{setting.phone}</a>
              </Typography>

            </div> */}
          </div>
        </div>
      </nav>
      {/*//nav*/}

    </header>
  );
}

const mapStateToProps = state => ({
  isLoggedIn: state.user.isLoggedIn,
  user: state.user.data
});

export default connect(mapStateToProps)(Nav);
