import {connect, useSelector} from "react-redux";

function useSetting(settingKeys, valueOnly=true) {

  if (!Array.isArray(settingKeys)) {
    throw new Error('Setting keys must be an array');
  }

  const settings = useSelector(state => state.setting.data);

  const result = {};

  settingKeys.map(itm => {

    let value = null;

    if(settings.hasOwnProperty(itm)) {
      value = valueOnly ? settings[itm].value : settings[itm];
    }

    result[itm] = value;
  });

  return result;
}

export default useSetting;
