import { makeStyles } from '@material-ui/core/styles';

export const menuStyles = makeStyles((theme) => ({
  categoryMenuItemElement: {
    cursor: 'pointer',
  },
  menuItemPrice: {
    fontSize: 17,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  selectedColor:{
    color: "#FFFFFF",
    
  },
  selectedBackgroundColor: {
    backgroundColor: `${theme.palette.primary.light} !important`,
  },
  categoryFont: {
    fontFamily: 'Cairo !important',
  },
  menuGroup: {
    width: '100%',
  },
  menuGroupHeading: {
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: 5
  },
  menuGroupHeadingText: {
    color: theme.palette.grey['50'],
  },
  menuGroupHeadingTextCount: {
    color: theme.palette.grey['300'],
  },
  menuGroupSecondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  menuGroupItems: {
    flexDirection: 'column'
  }
}));
// dfdfd
