import React, { useState } from 'react';
import PageHeader from '../../components/pageHeader/pageHeader';

// import { Viewer } from '@react-pdf-viewer/core';

// // Import the styles
// import '@react-pdf-viewer/core/lib/styles/index.css';
// import { Worker } from '@react-pdf-viewer/core';



import { connect } from 'react-redux';
import '../../assets/css/custom.scss';
import SlideAnimation from '../../components/util/Animations/SlideAnimation';
import { Button } from '@material-ui/core';


function Christmas() {


  return (<>

    <SlideAnimation in={true}>

      <PageHeader pageName="Christmas Season" bgClass="breadcrumb-bg-services" />

      {/* features-4 */}

      <section className="grids-1">
        <div className={`grids pt-3 pb-5 backgroundDark`}>
          <div className="container item-center">


            <div className="col-md-12 col-lg-12 col-sm-12 mb-3">

            <Button
                type="button"
                className="point-link"
                variant="contained"
                color="primary"
                target="_blank"
                href="/static/theme/ch-party-menu.pdf"
              >
                Download Our Christmas Party Menu
              </Button>
              <br />
              <Button
                type="button"
                className="point-link"
                variant="contained"
                color="primary"
                target="_blank"
                href="/static/theme/ch-party-day-menu.pdf"
              >
                Download Our Christmas Day Menu
              </Button>


              <div className="ent-image">
                      <img
                        src="/static/theme/images/ch-up1.jpg "
                        className="entertainment-image"
                        alt="best indian takeaways near me"
                      />
              </div>


              <div className="ent-image">
                      <img
                        src="/static/theme/images/ch-up2.jpg "
                        className="entertainment-image"
                        alt="best indian takeaways near me"
                      />
              </div>


              <div className="ent-image">
                      <img
                        src="/static/theme/images/ch-up3.jpg "
                        className="entertainment-image"
                        alt="best indian takeaways near me"
                      />
              </div>


              <div className="ent-image">
                      <img
                        src="/static/theme/images/ch-up4.jpg "
                        className="entertainment-image"
                        alt="best indian takeaways near me"
                      />
              </div>




            </div>


            {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
              <div
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.3)',
                  height: '720px',
                  width: '100%'
                }}
              >
                <Viewer fileUrl="/static/theme/royal-tiger-christmas-party-menu.pdf" />
              </div>

            </Worker> */}

            {/* <div className="col-md-12 col-lg-12 col-sm-12 mb-3">

              <Button
                type="button"
                className="point-link"
                variant="contained"
                color="primary"
                target="_blank"
                href="/static/theme/royal-tiger-christmas-menu.pdf"
              >
                Download Our Christmas Event Dates
              </Button>
            </div> */}


            {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
              <div
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.3)',
                  height: '720px',
                  width: '100%'
                }}
              >
                <Viewer fileUrl="/static/theme/royal-tiger-christmas-menu.pdf" />
              </div>

            </Worker> */}


          </div>

        </div>


      </section>

    </SlideAnimation>
  </>);
}

const mapStateToProps = state => ({
  user: state.user,
  date: state.cart.delivery.date,
  time: state.cart.delivery.time,
  opening: state.opening.data,
});

export default connect(mapStateToProps)(Christmas);
