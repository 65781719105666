import {
  CART_ITEM_ADD,
  CART_ITEM_REMOVE,
  CART_ITEM_ADDONS_UPDATE,
  CART_COUPON_UPDATE,
  CART_DELIVERY_ADDRESS_UPDATE,
  CART_DELIVERY_CHARGE_UPDATE,
  CART_DELIVERY_DATE_UPDATE,
  CART_DELIVERY_TIME_UPDATE,
  CART_ORDER_TYPE_UPDATE,
  CART_ORDER_NOTE_UPDATE,
  CART_CLEAR,
  CART_PAYMENT_STATUS_UPDATE,
  CART_PAYMENT_METHOD_UPDATE,
  CART_PAYMENT_AMOUNT_UPDATE,
  CART_UPDATE_ITEMS_TOTAL,
  CART_UPDATE_ADDONS_TOTAL,
  CART_DISCOUNT_UPDATE,
  CART_UPDATE_ASAP_TIME,
  CLEAR_COUPON,
  CLEAR_DISCOUNT,
  CART_ORDER_POINTS,
  CART_POINTS_TO_MONEY,
  CART_ADJUST_POINT_MONEY
} from './cartTypes';

///// Item
export const cartItemAdd = (item) => {

  return dispatch => {
    dispatch({
      type: CART_ITEM_ADD,
      payload: item
    });
    dispatch(cartUpdateItemsTotal());
    dispatch(cartUpdateAddonsTotal());
  }
};

export const clearCart = () => {
  return {
    type: CART_CLEAR,
  }
}

export const clearCoupon = () => {
  return {
    type: CLEAR_COUPON,
  }
}

export const clearDiscount = () => {
  return {
    type: CLEAR_DISCOUNT,
  }
}

export const cartItemRemove = (item) => {
  return dispatch => {
    dispatch({
      type: CART_ITEM_REMOVE,
      payload: item
    });
    dispatch(cartUpdateItemsTotal());
    dispatch(cartUpdateAddonsTotal());
  };
};

const cartUpdateItemsTotal = () => {
  return {
    type: CART_UPDATE_ITEMS_TOTAL
  };
};


///// Item Addon
export const cartItemAddonsUpdate = (item, addons) => {

  return dispatch => {
    dispatch({
      type: CART_ITEM_ADDONS_UPDATE,
      payload: { item, addons }
    });
    dispatch(cartUpdateAddonsTotal());
  };
};

const cartUpdateAddonsTotal = () => ({
  type: CART_UPDATE_ADDONS_TOTAL
});

///// Coupon
export const cartCouponUpdate = (coupon) => {
  return {
    type: CART_COUPON_UPDATE,
    payload: coupon
  };
};

export const cartDiscountUpdate = (discount) => {
  return {
    type: CART_DISCOUNT_UPDATE,
    payload: discount
  }
}

///// Address
export const cartDeliveryAddressUpdate = (address) => {
  return {
    type: CART_DELIVERY_ADDRESS_UPDATE,
    payload: address
  };
};

///// Delivery charge
export const cartDeliveryChargeUpdate = (distance, charge) => {
  return {
    type: CART_DELIVERY_CHARGE_UPDATE,
    payload: { distance, charge }
  };
};


///// Delivery date & time
export const cartDeliveryDateUpdate = (date) => {
  return {
    type: CART_DELIVERY_DATE_UPDATE,
    payload: date
  };
};

export const cartDeliveryTimeUpdate = (time) => {
  return {
    type: CART_DELIVERY_TIME_UPDATE,
    payload: time
  };
};

export const cartUpdateASAPTime = isAsap => {
  return {
    type: CART_UPDATE_ASAP_TIME,
    payload: isAsap
  };
}

///// Order type & note
export const cartOrderTypeUpdate = (type) => {
  return {
    type: CART_ORDER_TYPE_UPDATE,
    payload: type
  };
};

export const cartOrderNoteUpdate = (note) => {
  return {
    type: CART_ORDER_NOTE_UPDATE,
    payload: note
  };
};

///// Payment
export const cartPaymentAmountUpdate = (amount) => {
  return {
    type: CART_PAYMENT_AMOUNT_UPDATE,
    payload: amount
  };
};

export const cartPaymentStatusUpdate = (status) => {
  return {
    type: CART_PAYMENT_STATUS_UPDATE,
    payload: status
  };
};

export const cartPaymentMethodUpdate = (method) => {
  return {
    type: CART_PAYMENT_METHOD_UPDATE,
    payload: method
  };
};

export const updateCartOrderPoints = (payload) => {
  return {
    type: CART_ORDER_POINTS,
    payload: payload,
  };
};

export const updateCartPointsToMoney = (payload) => {
  return {
    type: CART_POINTS_TO_MONEY,
    payload: payload,
  };
};

export const updateAdjustPointMoney = (payload) => {
  return {
    type: CART_ADJUST_POINT_MONEY,
    payload: payload,
  }
}
