import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    paddingRight: '5px !important',
  },
  toolBar: {
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    justifyContent: 'end',
    color: '#fff'
  },
  closeBtn : {
    marginRight: '-20px'
  }
}));

export default useStyles;
