import React from 'react';
import PageHeader from '../../components/pageHeader/pageHeader';
import {Box, Button, Container} from '@material-ui/core';
import SlideAnimation from '../../components/util/Animations/SlideAnimation';
import Grid from '@material-ui/core/Grid';

const styles = {
  pdfButton: {
    maxWidth: 'fit-content'
  },

  imgContainer: {},
  vacancyImg: {
    width: '100%',
  },

}

const jobAdverts = [
  {pdf: 'job_advert_1.pdf', img: 'job_advert_1.png'},
  {pdf: 'job_advert_2.pdf', img: 'job_advert_2.png'},
];

const JobVacancy = () => {

  return (
    <SlideAnimation in={true}>
      <PageHeader pageName="Job Vacancy" bgClass="breadcrumb-bg-services"/>

      <Box sx={{backgroundColor: '#F6F2DF'}}>
        <Container>

          <Box sx={{paddingY: 10}}>
            <h1 className="latest-text mb-5">Job Vacancy</h1>
            <Grid
              container
              spacing={5}
              alignItems="flex-end"
              justifyContent="center"
            >
              {
                jobAdverts.map(advert => (
                  <Grid item xs={12} sm={6} lg={5} key={advert.img}>
                    <Box display="flex" flexDirection="column">
                      <Button
                        type="button"
                        className="point-link mb-3"
                        variant="contained"
                        color="primary"
                        target="_blank"
                        href={`/static/jobAdvert/${advert.pdf}`}
                        sx={styles.pdfButton}
                      >
                        Download PDF
                      </Button>
                      <Box
                        component="img"
                        src={`/static/jobAdvert/${advert.img}`}
                        alt="job vacancy"
                        sx={styles.vacancyImg}
                      />
                    </Box>
                  </Grid>
                ))
              }

            </Grid>
          </Box>

        </Container>
      </Box>

    </SlideAnimation>
  );
};

export default JobVacancy;
